import { useEffect, useState } from 'react';
import { Row, Col, Form, Input, Button, Card } from 'antd';
import { FileItem, UploadFile, CarouselModal } from 'components/index';
import { strings } from 'common/index';
import { ProjectsService, DeleteUploadedFilesServices } from 'src/api/services';
import { useRedux } from 'src/hooks';
import { Alert } from 'src/ui-kits';
import { FileType } from '../models';
import { useForm } from 'antd/lib/form/Form';
const UploadFileInspration = () => {
  type ModalType = {
    show: boolean;
    type: 'success' | 'warning' | 'error';
  };
  const { TextArea } = Input;
  const [modal, setModal] = useState<ModalType>({
    show: false,
    type: 'success',
  });

  let path = window.location.pathname;
  let idPath = path.split('/');
  const idOfproject = idPath[2];
  const { Save } = useRedux();
  const [carouselIndex, setCarouselIndex] = useState(0);
  const [showCarousel, setShowCarousel] = useState(false);
  const [disabled, setDisabled] = useState(false);
  const [uploadedDescription, setuploadedDescription] = useState('');
  const [files, setFiles] = useState<FileType[]>([]);
  const [loading, setLoading] = useState(false);
  const showCarouselImage = (index: number) => {
    setShowCarousel(true);
    setCarouselIndex(index);
  };
  const removeFilePress = async (fileId: number) => {
    if (!files) {
      return;
    }
    let newgoodsImage = [...files];
    let typeOfFile = newgoodsImage.find(function (value: any) {
      return value.id === fileId;
    });
    let editedgoodsImage = newgoodsImage.filter(function (value: any) {
      return value.id !== fileId;
    });
    if (typeOfFile?.type !== 'unUploaded') {
      let result = await DeleteUploadedFilesServices.deleteUploadedFiles(
        'good_attachment',
        fileId
      );
      if (result) {
        setFiles(editedgoodsImage);
      }
    } else {
      setFiles(editedgoodsImage);
    }
  };
  const addUploadPhoto = async () => {
    if (uploadedDescription === '') {
      setModal({ show: true, type: 'warning' });
      setTimeout(() => {
        setModal((prev) => ({ ...prev, show: false }));
      }, 3000);
      return;
    }
    let unploadNumber = 0;

    const formData: any = new FormData();
    setLoading(true);
    setDisabled(true);
    if (files.length !== 0) {
      for (let i = 0; i < files.length; i++) {
        if (files[i].type == 'unUploaded') {
          formData.append(`attachment`, files[i].file);
          formData.append(`attachment_title`, files[i].name);
          unploadNumber++;
          formData.append('description', uploadedDescription);
          await ProjectsService.addInsprationUploadFile(idOfproject, formData, {
            'content-Type': 'multipart/form-data',
          }).then((res) => {
            if (res) {
              setLoading(false);
              setModal({ show: true, type: 'success' });

              setTimeout(() => {
                setModal((prev) => ({ ...prev, show: false }));
                setDisabled(false);
                Save(
                  {
                    insprationContentStatus: 'insprationList',
                    checkStatusInspration: 'notSelected',
                  },
                  'app'
                );
              }, 1500);
            } else {
              setLoading(false);
              setDisabled(false);
              setModal({ show: true, type: 'error' });
              setTimeout(() => {
                setModal((prev) => ({ ...prev, show: false }));
              }, 1500);
            }
          });
        }
      }
    }
    localStorage.setItem('isFieldsTouched', JSON.stringify(false));
  };

  const [form] = useForm();

  const isFieldsTouched = form.isFieldsTouched(['attachment', 'description']);

  useEffect(() => {
    if (isFieldsTouched) {
      localStorage.setItem('isFieldsTouched', JSON.stringify(true));
    }
  }, [isFieldsTouched]);

  return (
    <Card className="form-container-inspiration">
      <div className="upload-file">
        <Form
          layout="vertical"
          form={form}
        >
          <Row className="second-row">
            <Col
              xs={24}
              xl={24}
              key={'id1'}
            >
              <Form.Item
                label={strings.attachment}
                name="attachment"
              >
                <UploadFile
                  setFiles={setFiles}
                  width={'100%'}
                  height={80}
                  text="Drag and drop files here"
                />
              </Form.Item>
              {files &&
                files.map((item: any, idx: number) => {
                  const { name, id, size, type, attachment_title } = item;

                  return (
                    <div className="file-items-container">
                      <FileItem
                        key={id}
                        format={item.format}
                        fileData={item.file.attachment}
                        name={type === 'unUploaded' ? name : attachment_title}
                        id={id}
                        size={size}
                        file={item}
                        type={type}
                        showImagePress={() => showCarouselImage(idx)}
                        removeFilePress={removeFilePress}
                        width={'100%'}
                      />
                    </div>
                  );
                })}
            </Col>
          </Row>
          <Row className="third-row">
            <Col
              xs={24}
              xl={24}
              key={'id1'}
            >
              <Form.Item
                label={strings.description}
                name="attachment"
              >
                <TextArea
                  rows={5}
                  value={uploadedDescription}
                  onChange={(e) => setuploadedDescription(e.target.value)}
                />
              </Form.Item>
            </Col>
          </Row>
          <Form.Item>
            <Button
              loading={loading}
              onClick={() => addUploadPhoto()}
              className="save-btn"
              disabled={(files && files.length === 0) || disabled}
            >
              {strings.save}
            </Button>
            <Button
              className="cancel-btn"
              onClick={() => {
                Save(
                  {
                    insprationContentStatus: 'notShowInspration',
                    checkStatusInspration: 'notSelected',
                    expandedItem: undefined,
                  },
                  'app'
                );
                localStorage.setItem('isFieldsTouched', JSON.stringify(false));
              }}
            >
              {strings.cancel}
            </Button>
          </Form.Item>
        </Form>
      </div>
      {showCarousel && (
        <CarouselModal
          onCancelPress={() => setShowCarousel(false)}
          isModalVisible={showCarousel}
          imageArray={files}
          defaultIndex={carouselIndex}
        />
      )}
      {modal.type === 'success' && modal.show && (
        <Alert
          description={'The inspiration has been added successfully'}
          type="success"
        />
      )}
      {modal.type === 'error' && modal.show && <Alert type="error" />}
      {modal.type === 'warning' && modal.show && (
        <Alert
          description={strings.pleaseFillAllInputs}
          type="warning"
        />
      )}
    </Card>
  );
};

export default UploadFileInspration;
