import { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Link } from 'react-router-dom';
import { Button, Menu, Dropdown, Card, List, Badge, message, Spin } from 'antd';
import {
  DownOutlined,
  SettingOutlined,
  BellOutlined,
  UserOutlined,
} from '@ant-design/icons';
import { AuthServices, NotificationsServices } from 'src/api/services';
import { LogoutSVG } from 'assets/images/index';
import { useRedux } from 'hooks/index';
import './styles.css';
function ProfileView(props: any) {
  const userId = localStorage.getItem('userId');
  const [data, setData] = useState<any[]>([]);

  const [unreadNotifs, setunreadNotifs] = useState<any>([]);
  const [loading, setLoading] = useState(false);
  const { Get, Save } = useRedux();
  const history = useHistory();
  const { expandedItem } = Get().app;

  const logoutUser = () => {
    history.push('/login');
    localStorage.clear();
  };
  const userDataJson: any = localStorage.getItem('userData');
  const userDataVar = JSON.parse(userDataJson);
  const { preferredName, email, role, fullname, avatar } = userDataVar;

  useEffect(() => {}, [userDataJson]);
  const getNotifs = async () => {
    if (userId) {
      setLoading(true);
      const notifResult = await AuthServices.getNotifs(userId);
      if (notifResult) {
        setLoading(false);

        setData(notifResult);
        let filterUndread = notifResult.filter((e: any) => !e.checked);
        if (filterUndread) {
          setunreadNotifs(filterUndread);
        }
      } else {
        setLoading(false);
      }
    }
  };
  const clearAllNotifs = async () => {
    if (unreadNotifs && unreadNotifs.length > 0) {
      // setLoading(true);

      for (let i of unreadNotifs) {
        if (i.id) {
          await NotificationsServices.checkNotif(i.id).then((res) => {
            if (res) {
              getNotifs();
            }
          });
        }
      }
    } else {
      setLoading(false);
      message.info({ content: 'No new message' });
    }
  };

  const handleCheckNotif = async (id: string, notification: any) => {
    if (id && !notification.checked) {
      await NotificationsServices.checkNotif(id).then((res) => {
        if (res) {
          getNotifs();
        }
      });
    }
  };

  const [mounted, setMounted] = useState(true);
  useEffect(() => {
    if (mounted) {
      getNotifs();
    }
    return () => {
      setMounted(false);
    };
  }, []);

  const notifMenu = (
    <Menu className="drop-notif">
      <Card
        bordered={false}
        title={'Notifications'}
      >
        {unreadNotifs && unreadNotifs.length > 0
          ? unreadNotifs.map((item: any, index: number) => {
              return (
                <List.Item
                  key={index}
                  onClick={() => handleCheckNotif(item.id, item)}
                >
                  <Link
                    style={{ fontSize: '1rem' }}
                    to={`/add-project/${item?.project}`}
                    onClick={() => {
                      return item.type !== null && item.extra_info !== null
                        ? item.type === 'conversation'
                          ? Save(
                              {
                                activeTab: 'MESSAGEBOARD',
                                expandedItem: item.extra_info.toString(),
                                conversationStatus: 'edit',
                              },
                              'app'
                            )
                          : item.type === 'comment'
                          ? Save(
                              {
                                activeTab: 'MESSAGEBOARD',
                                conversationStatus: 'chat',
                                expandedItem: item.extra_info.toString(),
                              },
                              'app'
                            )
                          : item.type === 'timeline'
                          ? Save(
                              {
                                activeTab: 'TIMELINE',
                                expandedItem: item.extra_info.toString(),
                                terminalContentStatus: 'edit',
                              },
                              'app'
                            )
                          : item.type === 'project_info'
                          ? Save({ activeTab: 'PROJECTDATA' }, 'app')
                          : item.type === 'no_type_yet'
                          ? Save({ activeTab: 'PROJECTDATA' }, 'app')
                          : ''
                        : null;
                    }}
                  >
                    <p
                      className={item.checked ? 'readed-notif' : 'unread-notif'}
                    >
                      {item.msg}
                    </p>
                  </Link>
                </List.Item>
              );
            })
          : 'There are no new messages'}
      </Card>
      {unreadNotifs && unreadNotifs.length > 0 && (
        <Button
          className="clear-all-notifs"
          onClick={() => clearAllNotifs()}
        >
          clear all
        </Button>
      )}
    </Menu>
  );
  const menu = (
    <Menu className="drop">
      <div className="profile-assets">
        {!avatar ? (
          <div className="no-avatar">
            <UserOutlined />
          </div>
        ) : (
          <img
            src={`${URL}/${avatar}`}
            alt=""
            width="100px"
            height="100px"
            style={{
              borderRadius: '50%',
              border: 'none',
            }}
          />
        )}

        <p>{preferredName}</p>
        <Button
          className="edit-profile"
          disabled={expandedItem !== undefined}
        >
          <Link to="/profile">
            {' '}
            <SettingOutlined /> Edit Profile
          </Link>
        </Button>
        <span></span>
        {expandedItem !== undefined ? (
          <p style={{ fontSize: '10px', color: 'red' }}>
            You have unsaved changes, Please Save the form or Cancel changes!
          </p>
        ) : (
          ''
        )}
      </div>
      <div className="informations">
        <p>Role</p>
        <p>{role}</p>

        <p>EMAIL</p>
        <p>{email}</p>
      </div>
      <div
        onClick={logoutUser}
        className="logout"
      >
        LOGOUT
        <img
          src={LogoutSVG}
          alt=""
        />
      </div>
    </Menu>
  );

  return (
    <>
      <span className="notifs-and-profile">
        <Dropdown
          overlay={notifMenu}
          trigger={['click']}
          autoFocus={true}
        >
          <Button onClick={(e) => e.preventDefault()}>
            <Badge count={loading ? <Spin /> : unreadNotifs.length}>
              <BellOutlined className="bell-notif" />
            </Badge>
          </Button>
        </Dropdown>

        {!avatar ? (
          <div className="no-avatar">
            <UserOutlined />
          </div>
        ) : (
          <img
            src={`${URL}${avatar}`}
            alt=""
            width="50px"
            height="50px"
            style={{
              borderRadius: '50%',
              border: 'none',
            }}
          />
        )}
        <Dropdown
          overlay={menu}
          trigger={['click']}
        >
          <Button onClick={(e) => e.preventDefault()}>
            {preferredName !== '' ? preferredName : fullname}
            <DownOutlined />
          </Button>
        </Dropdown>
      </span>
    </>
  );
}

export default ProfileView;
