import { Button } from "antd";
import { ClockCircleOutlined, CheckOutlined } from "@ant-design/icons";
import { PropsType } from "./models";

function InprogressButtons(props: PropsType) {
  const { status } = props;
  return (
    <Button className={status === "completed" ? "completed-btn" : ""}>
      {status !== "completed" && <ClockCircleOutlined />}
      <span>
        Status: {status === "in_progress" && <span> In progress</span>}
        {status === "inactive" && <span>Inactive</span>}
        {status === "working" && <span>Working</span>}
        {status === "completed" && (
          <span>
            Completed
            <CheckOutlined />
          </span>
        )}
      </span>
    </Button>
  );
}

export default InprogressButtons;
